import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import "../styling/formik.css";
import SubmitOrder from "./SubmitOrder";
//import AppSettings from "../../AppSettings";
import ContactPreference from "../components/ContactPreference";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import CreditCard from "./CreditCard";
import ShippingMethod from "../components/ShippingMethod";
import WillCallLocation from "../components/WillCallLocation";
import Accordion from "./Accordion";
import { usePaymentInputs } from "react-payment-inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLocalStorage from "../../context/useLocalStorage";
import { Link } from "react-router-dom";
import ApproveShipping from "./ApproveShipping";
import useMicroFlex from "./useMicroFlex";
import { findByZipCode } from "../../data/states";
import AppSettings from "../../AppSettings";

const ShippingNote = styled.div`
  margin-top: -10px;
  font-size: 12px;
  float: right;
  color: #4a4a4a;
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
`;

export default function CheckoutForm({
  parts,
  goto,
  clearCart,
  account,
  checkoutInfo,
  address,
  setAddress,
  zipcode,
  setZipcode,
  shippingMethod,
  setShippingMethod,
  custKey,
  shippingCost,
  isLoadingShipping,
  salesTax,
  tbd,
  setTbd,
  addressIndex,
  setAddressIndex,
  cardIndex,
  setCardIndex,
  isLoading,
  isQuote,
  invalidAddress
}) {
  const [activeStep, setActiveStep] = useState(1);
  const [requestedStep, setRequestedStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCategory, setErrorCategory] = useState("");
  const [paymentMethodState, setPaymentMethodState] = useState("");
  const context = useContext(AppContext);
  const formikRef = useRef();
  const isNewCreditCard = paymentMethodState === "Credit Card" && cardIndex === 0;

  // console.log("Formik Card Index & Payment Method", `${cardIndex} ${paymentMethodState}`);
  // console.log("New Credit Card", isNewCreditCard);
  const {validateCard, cardToken, cardError, autoCompleteData} = useMicroFlex(context.token, isNewCreditCard);

  useEffect(() => {
    if(!formikRef.current) return;

    if(autoCompleteData && autoCompleteData.name){
      formikRef.current.setFieldValue("nameOnCard", autoCompleteData.name || "");
    }          

    if(autoCompleteData && autoCompleteData.expirationMonth && autoCompleteData.expirationYear){
      formikRef.current.setFieldValue("expiryDate", `${autoCompleteData.expirationMonth} / ${autoCompleteData.expirationYear.slice(-2)}`);
    }

    if((cardToken && cardToken !== formikRef.current.state.values.cardNumber) || (!cardToken && formikRef.current.state.values.cardNumber)){
      formikRef.current.setFieldValue("cardNumber", cardToken ? cardToken : "");
    }

    formikRef.current.setFieldError("cardNumber", cardError);
  }, [autoCompleteData, cardToken, cardError]);

  useEffect(() => {
    if(pageData && pageData.cardIndex && pageData.cardIndex !== cardIndex){
      setCardIndex(pageData.cardIndex);
    }
  }, [pageData]);

  function deNullify(value) {
    return value ? value : "";
  }

  const initialValues = {
    name: deNullify(context.contactInfo.name),
    company: deNullify(context.contactInfo.company),
    email: deNullify(context.contactInfo.email),
    phone: deNullify(context.contactInfo.phone),
    po: "",
    preference: "",
    shipping: "UPS Ground",
    approveShipping: false,
    willCallLocation: "",
    addressIndex: 0,
    recipient: deNullify(context.contactInfo.company || context.contactInfo.name),
    address: deNullify(context.contactInfo.address),
    suite: "",
    city: deNullify(context.contactInfo.city),
    // state: deNullify(context.contactInfo.state),
    zipcode: deNullify(context.contactInfo.zipcode),
    paymentMethod: "",
    cardIndex: 0,
    cardNumber: "",
    expiryDate: "",
    rememberCard: false,
    nameOnCard: "",
    billingAddress: deNullify(context.contactInfo.address),
    billingCity: deNullify(context.contactInfo.city),
    billingState: deNullify(context.contactInfo.state),
    billingZipcode: deNullify(context.contactInfo.zipcode),
    billingCountry: "US",
    notes: "",
    isQuote: false
  };

  const checkoutStorageKey = "cpc-checkout";
  const [pageData, setPageData] = useLocalStorage(checkoutStorageKey, initialValues);

  // add new fields to pageData if pulled from local storage
  if (!pageData.nameOnCard)
    pageData.nameOnCard = deNullify(context.contactInfo.name);
  if (!pageData.billingCity)
    pageData.billingCity = deNullify(context.contactInfo.city);
  if (!pageData.billingState)
    pageData.billingState = deNullify(context.contactInfo.state);
  if (!pageData.billingCountry)
    pageData.billingCountry = "US";

  const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
    switch (cardType.displayName) {
      case "Visa":
        return;
      case "Mastercard":
        return;
      case "American Express":
        return;
      case "Discover":
        return;
      default:
        return "We only accept Mastercard, Visa, Amex and Discover";
    }
  };
  const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps } = usePaymentInputs({ cardNumberValidator });

  // This is a hack, but perhaps a reasonable one.
  // There was a problem in the interactions between the checkbox that
  // controls whether or not certain controls would be displayed and
  // the calculations within the Accordion control that cause it to
  // hide or display the Accordion contents.
  //
  // The Accordion wraps an inner and outer div and sets the maxHeight of a outer div
  // to zero or the scrollHeight of the inner div depending on whether the Accordion
  // is open or closed. The inner div contains the actual content of the Accordion so
  // the net effect is that the accordion can dynamically open and close.
  //
  // Within the Payment Information Accordion, we have a checkbox whose value determines
  // whether to show or hide a couple fields for collecting billing address.
  // Clicking that checkbox causes a rerender or the control, but due to the way
  // react optimizes rendering, the rerender of the checkbox state doesn't trigger
  // the recalculation of the content height until after the render is complete.
  //
  // We get around this by incrementing clickCount whenever a checkbox is clicked
  // within a setTimeout call.  This results in a another render cycle in which
  // the Accordion content is now rendered with the proper height so that the
  // controls whose visibility is determined by checkbox state are displayed.
  const [clickCount, setClickCount] = useState(1);
  const requestRefresh = () => setTimeout(() => setClickCount(clickCount + 1), 10);

  // Accept US, Canada and Mexican phone numbers
  const phoneRegExp = /^(\+?(011[. -]?)?\+?52[. -]?([0-9]{3}[. -]?[0-9]{3}[. -]?[0-9]{4}|[0-9]{2}[. -]?[0-9]{4}[. -]?[0-9]{4})|(\+?(1[. -]?)?(\(?[0-9]{3}\)?[. -]?[0-9]{3}[. -]?[0-9]{4})))(\s?(x|ext|ext.)\s?[0-9]{1,5})?$/;
  // Require first and last name
  const nameRegExp = /\w\w+\s+\w\w+/;
  const expiryDateRegExp = /[0-9][0-9] \/ [0-9][0-9]/;

  // Allows US and Canadian zipcodes
  // const zipcodeRegExp = /^(\d{5}(?:[-\s]\d{4})?)|([A-Za-z]\d[A-Za-z][-\s]?\d[A-Za-z]\d)$/;
  // Only allow US zipcodes
  const zipcodeRegExp = /^(\d{5}(?:[-\s]\d{4})?)$/;
  const canadianPostalRegExp = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

  // const microFlexValidation = function(value) {
  //   console.log("microFlexValidation", cardError);
  //   return !!cardError;
  // }
  function validExpiryDate(val) {
    if (!val) return true;
    const month = Number(val.substr(0, 2));
    const year = 2000 + Number(val.substr(5, 2));
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const yearDiff = year - currentYear;
    return month < 13 && month > 0 && year >= currentYear && yearDiff <= 20
      && (year > currentYear || (year === currentYear && month >= currentMonth));
  }
  let validations = {
    name: Yup.string()
      .required("Full name is required")
      .matches(nameRegExp, "Please provide your first and last name")
      .max(40, "Name may not exceed 40 characters"),
    company: Yup.string().max(40, "Company name may not exceed 40 characters"),
    email: Yup.string()
      .required("Email address is required")
      .email("Invalid email")
      .max(256, "Email address may not exceed 256 characters"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .max(17, "Phone number may not exceed 17 characters"),
    po: Yup.string()
      .test(
        "po-required",
        "PO Number or Job Name is required",
        val => !checkoutInfo || !checkoutInfo.PORequired || isQuote || val
      )
      .max(15, "PO Number / Job Name may not exceed 15 characters"),
    recipient: Yup.string().when(["shipping", "addressIndex"], {
      is: (shipping, addressIndex) => !shipping.startsWith("Will Call") && !addressIndex,
      then: Yup.string().required("Recipient is required")
        .max(40, "Recipient name may not exceed 40 characters")
    }),
    address: Yup.string().when(["shipping", "addressIndex"], {
      is: (shipping, addressIndex) => !shipping.startsWith("Will Call") && !addressIndex,
      then: Yup.string().required("Shipping address is required")
        .max(40, "Address may not exceed 40 characters")
    }),
    suite: Yup.string().when(["shipping", "addressIndex"], {
      is: (shipping, addressIndex) => !shipping.startsWith("Will Call") && !addressIndex,
      then: Yup.string().max(30, "Suite may not exceed 30 characters")
    }),
    city: Yup.string().when(["shipping", "addressIndex"], {
      is: (shipping, addressIndex) => !shipping.startsWith("Will Call") && !addressIndex,
      then: Yup.string().required("City is required")
        .max(20, "City may not exceed 20 characters")
    }),
    zipcode: Yup.string().when(["shipping", "addressIndex"], {
      is: (shipping, addressIndex) => !shipping.startsWith("Will Call") && !addressIndex,
      then: Yup.string().required("Zipcode is required")
        .matches(zipcodeRegExp, "Zipcode is not valid")
    }),
    willCallLocation: Yup.string().when("shipping", {
      is: val => val.startsWith("Will Call"),
      then: Yup.string().required("You must select a Case Parts Branch")
    }),
    paymentMethod: Yup.string().required("Payment method required"),
    // cardNumber: Yup.string().when(["paymentMethod", "cardIndex"], {
    //   is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
    //   then: Yup.string().required("Card number and CVV are required")
    // }),
    expiryDate: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().required("Expiration date is required")
        .matches(expiryDateRegExp, "Invalid expiration date")
        .test("expiry-future", "Out-of-range expiration date", validExpiryDate)
    }),
    nameOnCard: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().required("Cardholder name is required")
        .max(30, "Cardholder name may not exceed 30 characters")
    }),
    billingAddress: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().required("Billing address is required")
        .max(30, "Billing address may not exceed 30 characters")
    }),
    billingCity: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().required("Billing city is required")
        .max(30, "Billing city may not exceed 30 characters")
    }),
    billingState: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().when(["billingCountry"], (country) => {
        if (["CAN", "CA"].indexOf(country) >= 0) {
          return Yup.string().required("Billing province is required");
        } else {
          return Yup.string().required("Billing state is required")
        }
      })
    }),
    billingZipcode: Yup.string().when(["paymentMethod", "cardIndex"], {
      is: (payment, index) => payment === "Credit Card" && !index && !isQuote,
      then: Yup.string().when(["billingCountry"], (country) => {
        if (["CAN", "CA"].indexOf(country) >= 0) {
          return Yup.string().matches(canadianPostalRegExp, "Invalid postal code");
        } else {
          return Yup.string().matches(zipcodeRegExp, "Invalid Zipcode")
        }
      })
    })
  };

  const inputSchema = Yup.object().shape(validations);

  const FormField = ({ name, required, caption, type, component, placeholder, errors, touched, tabIndex }) => (
    <>
      <label htmlFor={name}>
        {caption}
        {required && <strong>*</strong>}
      </label>
      <ErrorMessage name={name} className="error" component="div" />
      <Field
        component={component || "input"}
        type={type || "text"}
        name={name}
        placeholder={placeholder}
        className={errors[name] && touched[name] ? "text-input error" : "text-input"}
        tabIndex={tabIndex}
      />
    </>
  );

  // function Checkbox({ field, type, caption, checked }) {
  //   return (
  //     <>
  //       {/* remove {...field} to see changes not propagated */}
  //       <input
  //         {...field}
  //         type={type}
  //         checked={checked}
  //         // see comment on clickCount definition to understand why this is needed
  //         onClick={() => setTimeout(() => setClickCount(clickCount + 1), 0)}
  //       />
  //       {caption}
  //     </>
  //   );
  // }

  const updateAddressInfo = (values, setFieldValue) => {
    // Update address to trigger recalculation of shipping costs
    if (values.shipping.startsWith("Will Call")) {
      if (address) {
        setAddress("");
      }
      if (zipcode) {
        setZipcode("");
      }
    } else {
      const a = addressIndex ? checkoutInfo.ShippingAddresses[addressIndex - 1].Street : values.address;
      const z = addressIndex ? checkoutInfo.ShippingAddresses[addressIndex - 1].Zipcode : values.zipcode;
      if (address !== a) {
        console.log("Set Address", a);
        setAddress(a);
      }
      if (zipcode !== z) {
        console.log("Set Zipcode", z);
        setZipcode(z);
      }
    }
    if (shippingCost && shippingCost !== "TBD" && values.approveShipping) {
      setFieldValue("approveShipping", false)
    }
  }

  const selectStep = (step, setFieldTouched, setFieldValue, values) => {
    setActiveStep(step === activeStep ? 0 : step);
    if (step > 1) {
      setFieldTouched("name");
      setFieldTouched("email");
      setFieldTouched("phone");
      setFieldTouched("po");
    }
    if (step > 2) {
      setFieldTouched("recipient");
      setFieldTouched("address");
      setFieldTouched("suite");
      setFieldTouched("city");
      //setFieldTouched("state");
      setFieldTouched("zipcode");
      setFieldTouched("willCallLocation");
    }
    if (step > 3) {
      setFieldTouched("paymentMethod");
      setFieldTouched("nameOnCard");
      setFieldTouched("billingAddress");
      setFieldTouched("billingZipcode");
      setFieldTouched("billingCity");
      setFieldTouched("billingState");
      setFieldTouched("cardNumber");
      setFieldTouched("expiryDate");
      if (!cardToken) validateCard();
    }
    console.log(`selectStep(${step})`);
    updateAddressInfo(values, setFieldValue);
    setPageData(values);
  };

  // If convert to quote while Payment Method is active, advance
  if (isQuote && activeStep === 3) {
    setTimeout(() => {
      setClickCount(clickCount + 1);
      setRequestedStep(4);
    }, 0);
  }

  return (
    <Formik
      validate={(values, props) => {
        const shipMethod = values.shipping === "Will Call" ? `Will Call - ${values.willCallLocation}` : values.shipping;
        if (shippingMethod !== shipMethod) {
          setShippingMethod(shipMethod);
        }

        // Will Call orders have no shipping, so update shipping costs
        if (tbd && values.shipping !== "TBD") {
          setTimeout(() => setTbd(false), 0);
        } else if (!tbd && values.shipping === "TBD") {
          setTimeout(() => setTbd(true), 0);
        }

        let errors = {};
        if (!isQuote && values.paymentMethod === "Credit Card" && cardIndex === 0 && cardError) {
          errors.cardNumber = cardError;
        }

        return errors;
    }}
    initialValues={pageData}
    validationSchema={inputSchema}
    ref={formikRef}
    onSubmit={async (values, { setSubmitting }) => {
      try {
        if (context.claims.Employee && context.claims.Customer) {
          setErrorMsg("Employees may not submit orders while impersonating customers");
          return;
        }
        console.log("Submit function invoked")
        //const cardType = meta.cardType ? meta.cardType.displayName : "";
        setErrorMsg("");
        setErrorCategory("");
        const { requestId, message } = await SubmitOrder(
          parts,
          values,
          account,
          custKey,
          // We need to make sure we pass zero for shipping cost if this is a
          // BillRecipient customer. Otherwise, no shipping cost shown on web
          // or OP, but the EstimatedShipping flows through to the database.
          values.shipping === "TBD" ? "TBD" : checkoutInfo && checkoutInfo.ShippingCustomerAccount ? 0 : shippingCost,
          salesTax,
          checkoutInfo,
          isQuote,
          //cardType,
          context.token,
          cardToken
        );

        context.updateContactInfo(values);
        window._chatlio.identify(values.email, {
          name: values.name,
          account: account ? account : "none",
          email: values.email,
          phone: values.phone,
          prefers: values.preference ? values.preference : "phone or email",
          company: values.company ? values.company : "none"
        });

        // Remove values that should not be reused on subsequent orders
        const savedValues = {
          ...values, approveShipping: false,
          cardIndex: 0, cardNumber: "", expiryDate: "", isQuote: false, nameOnCard: "",
          notes: "", po: ""
        }
        setPageData(savedValues)
        clearCart();

        goto(`/ThankYou?message=${message}&requestId=${requestId}&email=${values.email}`);
      } catch (err) {
        const errMessage = err.message ? err.message : "Unexpected Error";
        console.log(`Checkout request failed: ${errMessage}`);
        if (errMessage.startsWith("Payment:")) {
          setErrorCategory("Payment");
          setErrorMsg(errMessage.substring(9));
          setActiveStep(3);
        } else {
          setErrorMsg(errMessage);
        }
      }
      setSubmitting(false);
    }}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        validateField,
        //dirty,
        handleChange,
        handleBlur,
        //handleSubmit,
        //handleReset,
      }) => {
        // errorSteps is a boolean array that OR's together various conditions that indicate 
        // an error condition on each of the accordion tabs.
        const errorSteps = [
          // Step 0 doesn't exist, but javascript arrays start with zero, so we need this placeholder
          false,

          // Step 1: Customer Information
          (touched.name && errors.name) ||
          (touched.email && errors.email) ||
          (touched.phone && errors.phone) ||
          (touched.po && errors.po),

          // Step 2: Shipping Method
          (touched.recipient && errors.recipient) ||
          (touched.address && errors.address && !addressIndex) ||
          (touched.city && errors.city && !addressIndex) ||
          (touched.zipcode && errors.zipcode && !addressIndex) ||
          (values.zipcode && !findByZipCode(values.zipcode)) ||
          (touched.willCallLocation && errors.willCallLocation) ||
          invalidAddress,

          // Step 3: Payment Method
          (touched.paymentMethod && errors.paymentMethod) ||
          (values.paymentMethod === "Credit Card" && !cardIndex && (
            (touched.nameOnCard && errors.nameOnCard) ||
            (touched.billingAddress && errors.billingAddress) ||
            (touched.billingZipcode && errors.billingZipcode) ||
            (touched.billingZipcode && errors.billingCity) ||
            (touched.billingZipcode && errors.billingState) ||
            (touched.expiryDate && errors.expiryDate) ||
            (touched.cardNumber && errors.cardNumber))),

          // Step 4: Finalize  
          false
        ];
        const hasErrors = errorSteps[1] || errorSteps[2] || (errorSteps[3] && !isQuote) || errorSteps[4];

        const NextStepButton = ({ fieldNames, tabIndex }) => (
          <button
            type="button"
            className="continue"
            tabIndex={tabIndex}
            // Using onMouseDown instead of onClick to avoid missed events due to
            // onBlur out of other input controls causing onClick to be missed
            onMouseDown={() => {
              const nextStep = isQuote && activeStep === 2 ? 4 : activeStep + 1;
              if(activeStep === 3 && !cardToken && values.paymentMethod === "Credit Card" && values.cardIndex === 0){
                validateCard()
                .then((token) => {
                  fieldNames.forEach(name => {
                    setFieldTouched(name);
                  });
                  setPageData(values);
                  setTimeout(() => {
                    setClickCount(clickCount + 1);
                    setRequestedStep(nextStep);
                  }, 0);
                })
                .catch(() => { });
              }
              else {
                fieldNames.forEach(name => {
                  setFieldTouched(name);
                });
                setPageData(values);
                setTimeout(() => {
                  setClickCount(clickCount + 1);
                  setRequestedStep(nextStep);
                }, 0);
              }
            }}
          >
            Save &amp; Continue
          </button>
        );

        //This is stuff we only do when we're changing tabs
        if (requestedStep > activeStep) {
          if (!errorSteps[activeStep]) {
            // Delay update to avoid client error: Warning: Cannot update during an existing state transition
            setTimeout(() => setActiveStep(requestedStep), 0);
          }
          // Delay update to avoid client error: Warning: Cannot update during an existing state transition
          setTimeout(() => setRequestedStep(0), 0);

          updateAddressInfo(values, setFieldValue);
        }

        // Initialize the fields for credit card from earlier steps
        if (activeStep === 3) {
          if (values.name && values.nameOnCard === null) {
            setFieldValue("nameOnCard", values.name);
          }
          // if ((cardToken && cardToken !== values.cardNumber) || (!cardToken && values.cardNumber !== "")) {
          //   // Delay update to avoid client error: Warning: Cannot update during an existing state transition
          //   setTimeout(() => setFieldValue("cardNumber", cardToken ? cardToken : ""), 0);
          // }
          if (values.address && values.billingAddress === null) {
            setFieldValue("billingAddress", values.address);
          }
          if (values.zipcode && values.billingZipcode === null) {
            setFieldValue("billingZipcode", values.zipcode);
          }
        }

        if (isQuote !== values.isQuote) {
          setFieldValue("isQuote", isQuote);
        }

        // formik ref changes doesn't cause re-renders leaving stale values outside of this formik callback function
        if(values.paymentMethod && values.paymentMethod !== paymentMethodState){
          setPaymentMethodState(values.paymentMethod);
        }

        return (
          <div className="checkout">
            {errorMsg && <div className="checkoutError">
              <h2>{errorMsg}</h2>
              <p>
                Please check your input and try again<br />
                If problems persist, please contact us by phone, chat or email</p>
            </div>}
            <Form>
              <div>
                <Accordion
                  step="1"
                  title="Customer Information"
                  toggle={() => selectStep(1, setFieldTouched, setFieldValue, values)}
                  isActive={activeStep === 1}
                  isError={errorSteps[1]}
                >
                  <p className="formnote">
                    <strong>*</strong> Indicates a required field
                  </p>

                  <FormField
                    name="name"
                    required
                    caption="Name"
                    placeholder="Enter your full name"
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <FormField
                    name="company"
                    caption="Company"
                    placeholder="Enter company name, if any"
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <FormField
                    name="email"
                    required
                    caption="Email"
                    type="email"
                    placeholder="Enter email address"
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <FormField
                    name="phone"
                    required
                    caption="Phone"
                    placeholder="Enter phone number"
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <FormField
                    name="po"
                    required={checkoutInfo && checkoutInfo.PORequired}
                    caption="PO Number / Job Name"
                    placeholder="PO / Job name is required to place an order on account"
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <FormField
                    name="preference"
                    caption="Preferred Contact Method"
                    component={ContactPreference}
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 1 ? 0 : -1}
                  />
                  <NextStepButton fieldNames={["name", "email", "phone", "po"]} tabIndex={activeStep === 1 ? 0 : -1} />
                </Accordion>
                <Accordion
                  step="2"
                  title="Shipping Address"
                  toggle={() => selectStep(2, setFieldTouched, setFieldValue, values)}
                  isActive={activeStep === 2}
                  isError={errorSteps[2]}
                >
                  <ShippingNote>
                    <Icon icon={["far", "info-circle"]} />
                    <i>
                      <a href={AppSettings.ContactUs}>Email us</a> for international orders
                    </i>
                  </ShippingNote>
                  <p className="formnote">
                    <strong>*</strong> Indicates a required field
                  </p>
                  <FormField
                    name="shipping"
                    caption="Shipping Method"
                    component={ShippingMethod}
                    errors={errors}
                    touched={touched}
                    tabIndex={activeStep === 2 ? 0 : -1}
                  />
                  <ApproveShipping
                    setFieldValue={setFieldValue}
                    values={values}
                    shippingMethod={shippingMethod}
                    shippingCost={shippingCost}
                    isLoadingShipping={isLoadingShipping}
                    shippingCustomerAccount={checkoutInfo && checkoutInfo.ShippingCustomerAccount}
                    cartParts={context.cartParts}
                  />
                  {values.shipping.startsWith("Will Call") && (
                    <FormField
                      required
                      name="willCallLocation"
                      caption="Will Call Location"
                      component={WillCallLocation}
                      errors={errors}
                      touched={touched}
                      tabIndex={activeStep === 2 ? 0 : -1}
                    />
                  )}
                  {!values.shipping.startsWith("Will Call") && (
                    <ShippingAddress
                      values={values}
                      errors={errors}
                      touched={touched}
                      addresses={checkoutInfo ? checkoutInfo.ShippingAddresses : null}
                      addressIndex={values.addressIndex}
                      setAddressIndex={value => {
                        setAddressIndex(value);
                        setFieldValue("addressIndex", value);
                      }}
                      setFieldValue={setFieldValue}
                      requestRefresh={requestRefresh}
                      tabIndex={activeStep === 2 ? 0 : -1}
                    />
                  )}
                  {invalidAddress && <div className="error">Street address and zipcode are incompatible</div>}
                  <NextStepButton
                    fieldNames={["recipient", "address", "city", "zipcode", "willCallLocation"]}
                    tabIndex={activeStep === 2 ? 0 : -1}
                  />
                </Accordion>
                {!isQuote && (
                  <Accordion
                    step="3"
                    title="Payment Information"
                    toggle={() => selectStep(3, setFieldTouched, setFieldValue, values)}
                    isActive={activeStep === 3}
                    isError={errorSteps[3] || (errorCategory === "Payment" && activeStep === 3)}
                  >
                    <p className="formnote">
                      <strong>*</strong> Indicates a required field
                    </p>

                    <div>
                      <PaymentMethod
                        values={values}
                        errors={errors}
                        touched={touched}
                        paymentMethods={checkoutInfo ? checkoutInfo.PaymentMethods : null}
                        // Direct setFieldValue calls when changing payment method where generating warnings
                        // perhaps due to microFlex interaction.  The line below wraps the setFieldValue calls
                        // so that they execute after the render cycle.
                        setFieldValue={(name, value) => setTimeout(() => setFieldValue(name, value), 0)}
                        isLoading={isLoading && activeStep !== 3}
                        tabIndex={activeStep === 3 && !isQuote ? 0 : -1}
                      />
                      {values.paymentMethod === "On Account" && (
                        <p>
                          Your purchase will be charged to your account
                          <br />
                          and included on your monthly invoice.
                        </p>
                      )}
                      {values.paymentMethod === "Credit Card" &&
                        <CreditCard
                          values={values}
                          errors={errors}
                          touched={touched}
                          creditCards={checkoutInfo ? checkoutInfo.PaymentMethods.CreditCards : null}
                          cardIndex={values.cardIndex}
                          setCardIndex={value => {
                            setCardIndex(value);
                            setFieldValue("cardIndex", value);
                          }}
                          setFieldValue={setFieldValue}
                          requestRefresh={requestRefresh}
                          meta={meta}
                          getCardImageProps={getCardImageProps}
                          getCardNumberProps={getCardNumberProps}
                          getExpiryDateProps={getExpiryDateProps}
                          tabIndex={activeStep === 3 && !isQuote ? 0 : -1}
                          account={account}
                          validate={validateCard}
                          microformError={cardError}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          visible={!values.paymentMethod || values.paymentMethod === "Credit Card"}
                        />
                      }
                    </div>
                    <NextStepButton
                      fieldNames={[
                        "paymentMethod",
                        "nameOnCard",
                        "billingAddress",
                        "billingZipcode",
                        "billingState",
                        "billingCity",
                        "billingCountry",
                        "cardNumber",
                        "expiryDate"
                      ]}
                      tabIndex={activeStep === 3 && !isQuote ? 0 : -1}
                    />
                  </Accordion>
                )}
                <Accordion
                  step={isQuote ? "3" : "4"}
                  title={`Finalize ${isQuote ? "Quote" : "Order"}`}
                  toggle={() => selectStep(4, setFieldTouched, setFieldValue, values)}
                  isActive={activeStep === 4}
                  isError={errorSteps[4]}
                >
                  <div>
                    <FormField
                      name="notes"
                      caption="Anything else?"
                      component="textarea"
                      rows="5"
                      placeholder="Enter any special instructions"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  {hasErrors && (
                    <p className="error">You must correct the errors marked in red before you can submit your order.</p>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting || hasErrors}
                    tabIndex={(activeStep === 3 && isQuote) || activeStep === 4 ? 0 : -1}
                  >
                    {isQuote ? "Request Quote" : `Submit Order`}
                  </button>
                  {isSubmitting && (
                    <div>
                      <i>Submitting...</i>
                    </div>
                  )}
                </Accordion>
                {/* <p>{Object.keys(errors).length} Errors</p>
                <p>ERRORS: {JSON.stringify(errors)}</p>
                <p>ERROR STEPS: {JSON.stringify(errorSteps)}</p>
                <pre>TOUCHED: {JSON.stringify(touched)}</pre> */}
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
